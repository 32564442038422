import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import style from './Tag.module.css';
import type {ReactNode} from 'react';

export interface Props {
	color:
		| 'grey'
		| 'primary'
		| 'white'
		| 'orange'
		| 'yellow'
		| 'black'
		| 'green'
		| 'blue'
		| 'purple'
		| 'red'
		| 'pink';
	className?: string;
	children?: ReactNode;
}

export function Tag({color, className, children}: Props) {
	const classes = classNames(
		style.Tag,
		CSSVariants(style, 'TagColor', color),
		className,
	);

	return <span className={classes}>{children}</span>;
}

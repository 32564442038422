import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import {useRouter} from '../router';
import {Link, type LinkProps} from '../Link';
import {Stack} from '../Stack';
import {Heading} from '../Typography';
import style from './TabMenu.module.css';
import type {Color} from '../Typography/types';
import type {ReactNode} from 'react';

interface Tab<T extends string> {
	name?: T;
	to?: LinkProps['href'];
	shallow?: boolean;
	text: string;
	children?: ReactNode;
}

export interface Props<T extends string> {
	tabs: Tab<T>[];
	tabStyle?: 'underline' | 'accentUnderline' | 'highlight';
	fullWidth?: boolean;
	replace?: boolean;
	currentTab?: T;
	onTabSelect?: (name: T) => void;
	className?: string;
}

export function TabMenu<T extends string>({
	tabs,
	tabStyle = 'highlight',
	fullWidth,
	replace,
	onTabSelect,
	currentTab,
	className,
}: Props<T>) {
	const router = useRouter?.();

	const tabMenuClass = classNames(
		style.TabMenu,
		CSSVariants(style, 'TabMenu', tabStyle),
		fullWidth ? style.FullScreenTabMenu : null,
		className,
	);

	return (
		<div className={tabMenuClass}>
			{tabs.map((tab) => {
				const isCurrentTab =
					(currentTab && currentTab === tab.name) ||
					(typeof tab.to === 'string' && router?.asPath === tab.to) ||
					(typeof tab.to === 'object' && router?.asPath === tab.to.pathname);
				let textColor: Color = 'grey';
				if (
					(isCurrentTab && tabStyle === 'highlight') ||
					(isCurrentTab && tabStyle === 'accentUnderline')
				) {
					textColor = 'blurple';
				} else if (isCurrentTab && tabStyle === 'underline') {
					textColor = 'black';
				}

				return (
					<Link
						key={tab.text}
						href={tab.to}
						className={classNames(
							tabStyle === 'highlight' && style.TabMenuLinkHighlight,
						)}
						activeClassName={classNames(
							CSSVariants(style, 'ActiveTab', tabStyle),
						)}
						replace={replace}
						onClick={() => tab.name && onTabSelect?.(tab.name)}
					>
						<Stack direction="row" spacing={tab.children ? 'xs' : 'none'}>
							<Heading
								type={tabStyle === 'highlight' ? 'h5' : 'h6'}
								bold={isCurrentTab}
								color={textColor}
							>
								{tab.text}
							</Heading>
							{tab.children ? tab.children : null}
						</Stack>
					</Link>
				);
			})}
		</div>
	);
}

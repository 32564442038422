import classNames from 'classnames';
import {CSSVariants} from '@halp/util';
import style from './Label.module.css';

export type Color =
	| 'red'
	| 'yellow'
	| 'orange'
	| 'green'
	| 'grey'
	| 'blue'
	| 'purple'
	| 'white';

export interface Props {
	text: string;
	color: Color;
	className?: string;
}

export function Label({text, color, className}: Props) {
	const classes = classNames(
		style.Label,
		CSSVariants(style, 'LabelColor', color),
		className,
	);

	return <span className={classes}>{text}</span>;
}

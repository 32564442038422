import type {
	CoachingFunnelStage,
	QualificationStatus,
} from '@halp/util/constants';
import {
	QUALIFICATION_COLORS,
	FUNNEL_STAGE_NAMES,
	QUALIFICATION_STATUSES_NAMES,
} from '@halp/util/constants';
import {formatDateTime, formatIntakePeriod} from '@halp/util';
import {Avatar, Heading, Label, Paragraph, Stack, Tag} from '@halp/ui/';
import {useI18n} from '../../i18n';
import style from './StudentsTableColumns.module.css';
import type {ComponentProps} from 'react';

export type StudentsTableColumnsType =
	| 'name'
	| 'contact'
	| 'insertedAt'
	| 'country'
	| 'score'
	| 'studyLocation'
	| 'studyDate'
	| 'qualificationStatus'
	| 'priority'
	| 'membership'
	| 'funnelStage'
	| 'coach'
	| 'programRecommendation'
	| 'application'
	| 'archivalReason'
	| 'deadline';

interface Program {
	id: string;
	name: string;
	school: {
		id: string;
		name: string;
	};
}

interface Row {
	id: string;
	insertedAt?: string;
	user?: {
		id: string;
		first?: string | null;
		last?: string | null;
		uid?: string | null;
		email?: string | null;
		phone?: string | null;
		onshore?: boolean | null;
		country?: string | null;
	};
	priority: number;
	deadline?: Date;
	qualificationScore?: number | null;
	qualificationStatus?: QualificationStatus | null;
	qualificationComplete: boolean;
	targetStudyLocation?: string | null;
	name?: string | null;
	funnelStage?: CoachingFunnelStage | null;
	intakePeriod?: string | null;
	targetStudyDate?: string | null;
	verified: boolean;
	coach?: {
		id: string;
		name?: string | null;
	} | null;
	programRecommendations: {
		id: string;
		submissionDeadline?: Date | null;
		program: Program;
	}[];
	applications: {
		id: string;
		submissionDeadline?: Date | null;
		program: Program;
	}[];
	archivalReason?: string | null;
}

function NameColumn({user}: Row) {
	return (
		<Stack direction="row" justifyContent="flex-start" spacing="xs">
			<Avatar
				size="small"
				text={user?.first}
				color={user?.onshore ? 'secondary' : 'primary'}
			/>
			<Stack direction="column" alignItems="flex-start">
				<Heading type="h6">
					{user?.first} {user?.last}
				</Heading>
				<Paragraph type="body" color="grey">
					{user?.uid}
				</Paragraph>
			</Stack>
		</Stack>
	);
}

function ContactColumn({user}: Row) {
	return (
		<Stack direction="column" alignItems="flex-start">
			<Paragraph>{user?.phone}</Paragraph>
			<Paragraph>{user?.email}</Paragraph>
		</Stack>
	);
}

function Score({score}: {score?: number | null}) {
	let color: ComponentProps<typeof Tag>['color'] = 'purple';
	if (!score) {
		color = 'grey';
	} else if (score >= 85) {
		color = 'red';
	} else if (score >= 70) {
		color = 'yellow';
	}

	return (
		<Tag className={style.ScoreColumn} color={color}>
			{score ? `${score}%` : 'N/A'}
		</Tag>
	);
}

function ScoreColumn({qualificationScore}: Row) {
	return <Score score={qualificationScore} />;
}

function PriorityColumn({priority}: Row) {
	return <Score score={Math.round(priority)} />;
}

function TimeColumn({insertedAt}: Row) {
	return formatDateTime(insertedAt ?? '', 'DateShort');
}

function StudyDateColumn({intakePeriod}: Row) {
	return intakePeriod ? formatIntakePeriod(intakePeriod) : 'N/A';
}

function FunnelStageColumn({funnelStage}: Row) {
	const {t} = useI18n();

	return funnelStage && t(FUNNEL_STAGE_NAMES[funnelStage]);
}

function MemberStatusColumn({qualificationStatus, verified}: Row) {
	const {t} = useI18n();

	if (verified) {
		return <Label color={QUALIFICATION_COLORS.MEMBER} text="Member" />;
	} else if (qualificationStatus) {
		return (
			<Label
				color={QUALIFICATION_COLORS[qualificationStatus ?? 'NA']}
				text={t(QUALIFICATION_STATUSES_NAMES[qualificationStatus])}
			/>
		);
	}

	return <Label color={QUALIFICATION_COLORS.NA} text="N/A" />;
}

function QualificationStatusColumn({qualificationStatus}: Row) {
	const {t} = useI18n();
	return (
		<Label
			color={QUALIFICATION_COLORS[qualificationStatus ?? 'NA']}
			text={
				qualificationStatus
					? t(QUALIFICATION_STATUSES_NAMES[qualificationStatus])
					: 'N/A'
			}
		/>
	);
}

function ProgramColumn({programRecommendations}: Row) {
	if (!programRecommendations || programRecommendations.length === 0) {
		return null;
	}

	const rec = programRecommendations.sort((a, b) => {
		const dateA = Number(a.submissionDeadline) || Number.MAX_SAFE_INTEGER;
		const dateB = Number(b.submissionDeadline) || Number.MAX_SAFE_INTEGER;
		return dateA - dateB;
	})[0];

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="stretch"
			spacing="xs"
		>
			<Stack direction="column" alignItems="flex-start">
				<Heading type="h6">{rec.program.school.name}</Heading>
				<Paragraph type="subtitle2">{rec.program.name}</Paragraph>
			</Stack>
		</Stack>
	);
}

function ApplicationColumn({applications}: Row) {
	if (!applications || applications.length === 0) {
		return null;
	}

	const application = applications.sort((a, b) => {
		const dateA = Number(a.submissionDeadline) || Number.MAX_SAFE_INTEGER;
		const dateB = Number(b.submissionDeadline) || Number.MAX_SAFE_INTEGER;
		return dateA - dateB;
	})[0];

	return (
		<Stack
			direction="row"
			justifyContent="space-between"
			alignItems="stretch"
			spacing="xs"
		>
			<Stack direction="column" alignItems="flex-start">
				<Heading type="h6">{application.program.school.name}</Heading>
				<Paragraph type="subtitle2">{application.program.name}</Paragraph>
			</Stack>
		</Stack>
	);
}

function ArchivalReasonColumn({archivalReason}: Row) {
	return archivalReason ?? 'Inactive';
}

function DeadlineColumn({deadline}: Row) {
	return deadline ? formatDateTime(deadline, 'DateMonthShort') : null;
}

export function useStudentsTableColumns(columns: StudentsTableColumnsType[]) {
	const tabColumns = {
		/* eslint-disable @typescript-eslint/no-explicit-any */
		name: {
			key: 'name',
			label: 'Name',
			sortable: false,
			width: 250,
			customCellRender: NameColumn as any,
		},
		contact: {
			key: 'user.email',
			label: 'Contact',
			sortable: false,
			width: 320,
			customCellRender: ContactColumn as any,
		},
		insertedAt: {
			key: 'insertedAt',
			label: 'Sign Up Date',
			sortable: true,
			customCellRender: TimeColumn as any,
		},
		country: {
			key: 'user.country',
			label: 'Country',
			width: 150,
			sortable: false,
		},
		score: {
			key: 'qualificationScore',
			label: 'QScore',
			sortable: true,
			width: 100,
			customCellRender: ScoreColumn as any,
		},
		priority: {
			key: 'priority',
			label: 'Priority',
			sortable: true,
			width: 100,
			customCellRender: PriorityColumn as any,
		},
		studyLocation: {
			key: 'targetStudyLocation',
			label: 'Study Country',
			width: 150,
			sortable: false,
		},
		studyDate: {
			key: 'targetStudyDate',
			label: 'Intake Period',
			sortable: true,
			width: 150,
			customCellRender: StudyDateColumn as any,
		},
		qualificationStatus: {
			key: 'qualificationStatus',
			label: 'Qual Status',
			sortable: false,
			customCellRender: QualificationStatusColumn as any,
		},
		membership: {
			key: 'qualificationStatus',
			label: 'Member Status',
			sortable: false,
			customCellRender: MemberStatusColumn as any,
		},
		funnelStage: {
			key: 'funnelStage',
			label: 'Funnel Stage',
			sortable: false,
			customCellRender: FunnelStageColumn as any,
		},
		coach: {
			key: 'coach.name',
			label: 'Coach',
			sortable: false,
		},
		programRecommendation: {
			key: 'programRecommendations',
			label: 'Program',
			sortable: false,
			width: 270,
			customCellRender: ProgramColumn as any,
		},
		application: {
			key: 'applications',
			label: 'Applications',
			sortable: false,
			width: 400,
			customCellRender: ApplicationColumn as any,
		},
		archivalReason: {
			key: 'archivalReason',
			label: 'Archived',
			sortable: false,
			customCellRender: ArchivalReasonColumn as any,
		},
		deadline: {
			key: 'deadline',
			label: 'Deadline',
			sortable: true,
			customCellRender: DeadlineColumn as any,
		},
		/* eslint-enable @typescript-eslint/no-explicit-any */
	};

	return columns.map((columns) => tabColumns[columns]);
}
